import { combineReducers } from "redux";
import facilitatorDeliveryReducer from "../containers/facilitatorDelivery/reducer";
import presentationTemplateReducer from "../containers/presentationTemplateComponent/reducer";
import presentationTemplateListReducer from '../containers/presentationTemplateList/reducer';
import presentationSlideListReducer from '../containers/presentationTemplateSlideList/reducer';
import mainReducer from "../containers/reducer"


export function createReducer() {
  const rootReducer = combineReducers({
    presentationTemplateReducer,
    facilitatorDeliveryReducer,
    presentationTemplateListReducer,
    presentationSlideListReducer,
    mainReducer
  });

  return rootReducer;
}
