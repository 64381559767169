export const SET_PRESENTATION_SLIDES = 'SET_PRESENTATION_SLIDES';
export const SET_FACILITATOR_TOKEN = 'SET_FACILITATOR_TOKEN';
export const SET_PARTICIPANTS_LIST = 'SET_PARTICIPANTS_LIST';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const cancelPresentationURL = courseId =>
  `${process.env.IDI_ASP_BASE_URL}/dashboard.asp#${courseId}-_activity-dbmodify-Course--Profiles--`;
export const SET_COURSE_PRESENTATION_ID = 'SET_COURSE_PRESENTATION_ID';
export const SET_COURSE_ID = 'SET_COURSE_ID';
export const SET_LANGUAGE_CODE = 'SET_LANGUAGE_CODE';
export const SET_FACILITATOR_NAME = 'SET_FACILITATOR_NAME';
