import { SET_PRESENTATION_TEMPLATE_ID, SET_PRESENTATION_TEMPLATE_TOKEN, SET_EDIT_TEMPLATE_DATA } from './constants'

const initialState = {
  presentationTemplateId: 0,
  presentationTemplateToken: null,
  editTemplateData: {}
};

const presentationTemplateListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRESENTATION_TEMPLATE_ID:
      return { ...state, presentationTemplateId: action.payload || 0 };

    case SET_PRESENTATION_TEMPLATE_TOKEN:
      return { ...state, presentationTemplateToken: action.payload || null }
    case SET_EDIT_TEMPLATE_DATA:
      return { ...state, editTemplateData: action.payload || {} }
    default:

      return state;
  }
};

export default presentationTemplateListReducer;
