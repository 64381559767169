import { REMOVE_ERROR, SET_ERROR } from './constants';

const initialState = {
  error: [],
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, error: [...state.error, action.payload] || [] };
    case REMOVE_ERROR:
      return { ...state, error: action.payload || [] };
    default:
      return state;
  }
};

export default mainReducer;
