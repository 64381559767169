import {
  SET_PRESENTATION_SLIDES,
  SET_FACILITATOR_TOKEN,
  SET_PARTICIPANTS_LIST,
} from './constants';

const initialState = {
  presentationSlides: [],
  presenterToken: '',
  participantList: [],
};

const facilitatorDeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRESENTATION_SLIDES:
      return { ...state, presentationSlides: action.payload || [] };

    case SET_FACILITATOR_TOKEN:
      return { ...state, presenterToken: action.payload || '' };

    case SET_PARTICIPANTS_LIST:
      return { ...state, participantList: action.payload || [] };

    default:
      return state;
  }
};

export default facilitatorDeliveryReducer;
