import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom'
import { routePath } from '../routePaths'

const PresentationTemplateComponent = lazy(() => import('../presentationTemplateComponent'))
const PresentationTemplateList = lazy(() => import('../presentationTemplateList'))
const PresentationTemplateSlideComponent = lazy(() => import('../presentationTemplateSlide'))
const PresentationTemplateSlideList = lazy(() => import('../presentationTemplateSlideList'))
const TemplateRoutes = () => (
  <Routes>
    <Route path={routePath.templateList} element={<PresentationTemplateList />} />
    <Route path={routePath.createTemplate} element={<PresentationTemplateComponent />} />
    <Route path={routePath.editTemplate} element={<PresentationTemplateComponent />} />
    <Route path={routePath.slidesList} element={<PresentationTemplateSlideList />} />
    <Route path={routePath.createSlide} element={<PresentationTemplateSlideComponent />} />
    <Route path={routePath.editSlide} element={<PresentationTemplateSlideComponent />} />
  </Routes>
)

export default TemplateRoutes
