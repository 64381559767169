import {SET_EDIT_SLIDE_DATA} from './constants'

const initialState = {
    editSlideData: {}
  };


  const presentationSlideListReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_EDIT_SLIDE_DATA:
      return {...state, editSlideData: action.payload || {}}
      default:
      return state;
    }
  }


  export default presentationSlideListReducer;