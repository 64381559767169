/* eslint-disable prettier/prettier */


import React, { lazy, Suspense } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { routePath } from '../routePaths';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import Spinner from 'react-bootstrap/Spinner';

import TemplateRoutes from './templateRoutes';
const FacilitatorDeliveryComponent = lazy(() => import('../facilitatorDelivery'));
const ParticipantsDeliveryComponent = lazy(() => import('../participantDelivery'));


function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<Spinner animation='border' role='status' />}>
          <Routes>
            <Route
              path={routePath.facilitatorDelivery}
              element={<FacilitatorDeliveryComponent />}
            />
            <Route
              path={routePath.participantDelivery}
              element={<ParticipantsDeliveryComponent />}
            />
            <Route
              path="/*"
              element={<TemplateRoutes />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
